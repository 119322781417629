import { Button, ButtonProps, Text } from "@chakra-ui/react";
import React from "react";

import { TSError } from "foundation-ts/tserrors";

import { $g1Color } from "../@chakra-ui/gatsby-plugin/G1Style";

import { ButtonVariant, Component } from "../utils/TypesAndConstants";

export interface G1ButtonProperties extends ButtonProps {
    component: Component ;
    variant: ButtonVariant  ;
    title: string ;
}

export const G1Button = (props: G1ButtonProperties) => {
    const { component, variant, title, fontSize, disabled, ...rest } = props ;
    
    if ((component === "connection") && (variant !== "ok") && (variant !== "cancel")) {
        TSError.throw(`${component}.${variant} is unknown`) ;
    }

    return disabled ? 
        <Button
            bg={$g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}bg`)}
            color={$g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}write`)}
            _hover={{ bg: $g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}bg`) }}
            disabled
            {...rest}
        >
            <Text fontSize={fontSize}>{title}</Text>
        </Button>
        :
        <Button
            bg={$g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}bg`)}
            color={$g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}write`)}
            _active={{ bg: $g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}select.bg`), color: $g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}select.write`) }}
            _hover={{ bg: $g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}hover.bg`), color: $g1Color(`${component}.buttons.${variant === "normal" ? "" : `${variant}.`}hover.write`) }}
            {...rest}
        >
            <Text fontSize={fontSize}>{title}</Text>
        </Button>
    ;
} ;